export const groupList = [
  {
    label: '物业',
    value: 'k01',
  },
  {
    label: '维修工程师',
    value: 'k02',
  },
  {
    label: '区域信息员',
    value: 'k03',
  },
  {
    label: '区域负责人',
    value: 'k04',
  },
  {
    label: '集团负责人',
    value: 'k05',
  },
  {
    label: '责任班组',
    value: 'b01',
  },
]
export const permissionLevel = [
  {
    label: '集团',
    value: 0,
  },
  {
    label: '区域',
    value: 1,
  },
  {
    label: '项目',
    value: 2,
  },
  {
    label: '楼栋',
    value: 3,
  },
]

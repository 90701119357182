<template>
  <a-modal
    :title="modal.title"
    v-model="modal.visible"
    :width="600"
    @ok="handleOk"
    @cancel="handleCancel"
    destroyOnClose
  >
    <a-table :dataSource="modal.data.permissionList" :pagination="false">
      <a-table-column title="类型" dataIndex="permissionLevel" align="center">
        <template slot-scope="text">
          <span>{{ translateType(text, 'permissionLevel') }}</span>
        </template>
      </a-table-column>
      <a-table-column title="名称" dataIndex="commonName" align="center"></a-table-column>
      <a-table-column title="编码" dataIndex="commonCode" align="center"></a-table-column>
    </a-table>
  </a-modal>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
import { permissionLevel } from '../../services/commonTypes'
export default {
  mixins: [queryViewMixin],
  data() {
    return {
      modal: {
        visible: false,
        title: '权限详情',
        data: {},
      },
    }
  },
  computed: {
    typesMap() {
      return {
        permissionLevel,
      }
    },
  },
  methods: {
    async start({ groupCode, umsAccountId }) {
      let permissionList = []
      const permissionLevel = this.code2PermissionLevel(groupCode)
      try {
        let res
        if (permissionLevel === 1) {
          res = await this.$axios.post('/fast-repair/admin/projectUserPermission/queryAreaByCode', {
            groupCode,
            umsAccountId,
          })
          permissionList = res.data
        } else if (permissionLevel === 2) {
          res = await this.$axios.post('/fast-repair/admin/projectUserPermission/queryProjectByCode', {
            groupCode,
            umsAccountId,
          })
          permissionList = res.data
        } else if (permissionLevel === 3) {
          res = await this.$axios.post('/fast-repair/admin/projectUserPermission/queryBuildingByCode', {
            groupCode,
            umsAccountId,
          })
          permissionList = res.data
        }
      } catch (e) {}

      this.modal.data = {
        permissionList,
        permissionLevel,
      }
      this.modal.visible = true
      return new Promise((resolve, reject) => {
        this.$once('end', function(state, result) {
          if (state === 'ok') {
            resolve(result)
          }
          if (state === 'cancel') {
            reject(new Error('E_CANCEL'))
          }
          this.modal.visible = false
        })
      })
    },
    code2PermissionLevel(groupCode) {
      // 楼栋
      if (['k01', 'k02'].includes(groupCode)) {
        return 3
        // 项目
      } else if (['b01'].includes(groupCode)) {
        // 区域
        return 2
      } else if (['k03', 'k04'].includes(groupCode)) {
        return 1
      }
      return 0
    },
    async handleOk() {
      this.$emit('end', 'ok', {})
    },
    handleCancel() {
      this.$emit('end', 'cancel')
    },
  },
  mounted() {},
}
</script>

<style></style>
